(function($) {
    'use strict';

    const NAVOPEN = 'nav-open',
        masthead = $('#masthead');

    $('#mainNav')
        .on('show.bs.collapse', function (e) {
            masthead.addClass(NAVOPEN);
        })

        .on('hide.bs.collapse', function (e) {
            masthead.removeClass(NAVOPEN);
        });
    
})(jQuery);

